import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface studentData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: studentData = {
  current: {},
  list: [],
  all: [],
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    setStudent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setStudents: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllStudents: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllStudents, setStudent, setStudents } = studentSlice.actions;
export default studentSlice.reducer;
