import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface promotionData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: promotionData = {
  current: {},
  list: [],
  all: [],
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    setPromotion: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setPromotions: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllPromotions: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllPromotions, setPromotion, setPromotions } =
  promotionSlice.actions;
export default promotionSlice.reducer;
