import { useFormik } from "formik";
import React from "react";
import { DillInput } from "../../components/input";

export function CreateFaculty() {
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "faculty",
    },
    onSubmit: onSave,
  });

  async function onSave() {}

  return (
    <React.Fragment>
      <main className="main">
        <div className="container">
          <div>
            <DillInput formik={formik} name="name" label="Nom de la faculté" />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
