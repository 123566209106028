import { Table } from "antd";
import React from "react";

export function FacultyScreen() {
  return (
    <React.Fragment>
      <main className="main">
        <Table
          className="table"
          columns={[
            {
              title: "Nom",
            },
            {
              title: "Départments",
            },
            {
              title: "Promotions",
            },
            {
              title: "Etudiants",
            },
          ]}
        />
      </main>
    </React.Fragment>
  );
}
