import { routeInterface } from "../routes";
import { DepartmentScreen } from "../screens/department";
import { CreateDepartment } from "../screens/department/createDept";
import { FacultyScreen } from "../screens/faculty";
import { CreateFaculty } from "../screens/faculty/createFac";
import { HomeScreen } from "../screens/home";
import { CreateOption } from "../screens/option/createOption";
import { PromotionScreen } from "../screens/promotion";

export const UserRoutes: routeInterface[] = [
  {
    component: null,
    icon: `fa-solid fa-table-tree`,
    isMenu: true,
    label: "Structure",
    layout: "/admin",
    name: "structure",
    path: "#",
    subscribeable: false,
    priority: 1,
    group: "structure",
  },
  {
    component: PromotionScreen,
    icon: `fa-solid fa-graduation-cap`,
    isMenu: true,
    label: "Promotions",
    layout: "/admin",
    name: "promotion",
    path: "/promotion",
    subscribeable: false,
    parent: "structure",
    priority: 1,
  },
  {
    component: FacultyScreen,
    icon: `fa-solid fa-building-columns`,
    isMenu: true,
    label: "Facultés",
    layout: "/admin",
    name: "fac",
    path: "/faculty",
    subscribeable: false,
    parent: "structure",
    priority: 2,
  },
  {
    component: DepartmentScreen,
    icon: `fa-solid fa-house-building`,
    isMenu: true,
    label: "Département",
    layout: "/admin",
    name: "department",
    path: "/department",
    subscribeable: false,
    parent: "structure",
    priority: 3,
  },
  {
    component: HomeScreen,
    icon: `fa-solid fa-gauge-simple-min`,
    isMenu: true,
    label: "Tableau de bord",
    layout: "/admin",
    name: "dashboard",
    path: "/",
    subscribeable: false,
    priority: 0,
  },
  {
    component: CreateFaculty,
    icon: `fa-solid fa-gauge-simple-min`,
    isMenu: true,
    label: "Ajouter une faculté",
    layout: "/admin",
    name: "fac-create",
    path: "/faculty/create",
    subscribeable: false,
    priority: 2,
    parent: "structure",
  },
  {
    component: CreateDepartment,
    icon: `fa-solid fa-gauge-simple-min`,
    isMenu: true,
    label: "Ajouter un départment",
    layout: "/admin",
    name: "department-create",
    path: "/department/create",
    subscribeable: false,
    priority: 3,
    parent: "structure",
  },
  {
    component: CreateOption,
    icon: `fa-solid fa-gauge-simple-min`,
    isMenu: true,
    label: "Ajouter une option",
    layout: "/admin",
    name: "option-create",
    path: "/option/create",
    subscribeable: false,
    priority: 3,
    parent: "structure",
  },
  {
    component: CreateOption,
    icon: `fa-solid fa-gauge-simple-min`,
    isMenu: true,
    label: "Ajouter une promotion",
    layout: "/admin",
    name: "promotion-create",
    path: "/promotion/create",
    subscribeable: false,
    priority: 1,
    parent: "structure",
  },
];
