import { Button, Card, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { DillInput } from "../components/input";
import userService from "../services/userService";
import { phoneRegex } from "../utils/helpers/regex";
import userHelper from "../utils/helpers/userHelper";
import { RootState } from "../redux/store";
import { isEmpty } from "lodash";

export function LoginScreen() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profil);

  async function init() {
    const elem = await userHelper.getUserProfil();
    if (!isEmpty(elem)) {
      userHelper.setCurrentUser(elem, dispatch);
    }
  }

  React.useEffect(() => {
    init();
  }, [user]);
  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: yup.object({
      phone: yup
        .string()
        .required("Champ requis")
        .matches(phoneRegex, { message: "Le format autorisé:+xxxxxxxxxx" }),
    }),
    onSubmit: onSave,
  });

  async function onSave() {
    const { phone } = formik.values;
    setLoading(true);
    await userService
      .getByKey(`phone=${encodeURIComponent(phone)}`)
      .then(async (response) => {
        const data = userService.getData(response);
        if (data.length === 0) {
          notification.warning({
            message: "Connexion",
            description: "Ce numéro n'est lié à aucun compte",
          });
          setLoading(false);
        } else {
          const user = data[0];
          await userHelper.setCurrentUser(user, dispatch);
          window.location.href = "/admin";
        }
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur de connexion",
          description: error,
        });
        setLoading(false);
      });
  }

  return (
    <main
      className="row"
      style={{
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        alignItems: "center",
      }}
    >
      <h1 style={{ fontWeight: "bold", fontSize: 46 }}>{`ARKA`}</h1>
      <Card
        className="card"
        style={{ maxWidth: 500, margin: "auto", width: "100%" }}
      >
        <p style={{ textAlign: "center", padding: 12, fontWeight: "500" }}>
          {`Veuillez renseigner votre numéro de téléphone afin de vous connecter`}
        </p>
        <div>
          <DillInput
            formik={formik}
            label="Téléphone"
            name="phone"
            type="tel"
            placeholder={"Numéro de téléphone au format:+xxxxxxx"}
          />
        </div>
        <div style={{ textAlign: "right", padding: "10px 0" }}>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            onClick={() => formik.handleSubmit()}
          >
            {`Se connecter`}
          </Button>
        </div>
      </Card>
    </main>
  );
}
