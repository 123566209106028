import { useFormik } from "formik";
import React from "react";
import { DillInput } from "../../components/input";
import { Switch } from "antd";

export function CreateOption() {
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "option",
      department: "",
      faculty: "",
    },
    onSubmit: onSave,
  });

  async function onSave() {}

  return (
    <React.Fragment>
      <main className="main">
        <div className="container">
          <div>
            <DillInput formik={formik} name="name" label="Nom de l'option" />
            <Switch checkedChildren="Lier à une faculté" />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
