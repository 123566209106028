import { routeInterface } from "../routes";
import { HomeScreen } from "../screens/home";
import { StudentScreen } from "../screens/student";
import { CreateStudentScreen } from "../screens/student/create";

export const StudentRoutes: routeInterface[] = [
  {
    component: null,
    icon: `fa-solid fa-screen-users`,
    isMenu: true,
    label: "étudiants",
    layout: "/admin",
    name: "student-group",
    path: "#",
    subscribeable: false,
    priority: 2,
    group: "student",
  },
  {
    component: StudentScreen,
    icon: `fa-solid fa-screen-users`,
    isMenu: true,
    label: "Liste",
    layout: "/admin",
    name: "student-list",
    path: "/students",
    subscribeable: false,
    priority: 1,
    parent: "student",
  },
  {
    component: CreateStudentScreen,
    icon: `fa-solid fa-screen-users`,
    isMenu: true,
    label: "Ajouter",
    layout: "/admin",
    name: "student-create",
    path: "/student/create",
    subscribeable: false,
    priority: 2,
    parent: "student",
  },
  {
    component: null,
    icon: `fa-solid fa-screen-users`,
    isMenu: false,
    label: "Voir",
    layout: "/admin",
    name: "student-view",
    path: "/student/:id/view",
    subscribeable: false,
    priority: 3,
    parent: "student",
  },
];
