import { Table } from "antd";
import React from "react";

export function PromotionScreen() {
  return (
    <React.Fragment>
      <main className="main">
        <Table
          className="table"
          columns={[
            {
              title: "Nom",
            },
            {
              title: "Faculté",
            },
            {
              title: "Département",
            },
            {
              title: "Option",
            },
            {
              title: "Etudiants",
            },
          ]}
        />
      </main>
    </React.Fragment>
  );
}
