import { isEmpty, split } from "lodash";
import React, { PropsWithChildren } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { routes } from "../routes";
import userHelper from "../utils/helpers/userHelper";
import { Footer } from "./footer";

export const Navbar: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const current = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [show, setShow] = React.useState(false);

  function isCurrent(path: string) {
    if (path === current.pathname) {
      return true;
    } else {
      return false;
    }
  }

  function getCurrent() {
    const path = current.pathname;
    const item = routes.find((p) => `${p.layout}${p.path}` === path);
    if (!isEmpty(item)) {
      return item.title ?? item.label;
    } else {
      return "";
    }
  }

  function onLogout() {
    userHelper.setLogout(dispatch);
    window.location.href = "/";
  }

  React.useEffect(() => {
    const link = split(params["*"] ?? "", "/");
    setShow(link?.length > 1 ?? false);
  }, [params]);

  function handleReturn() {
    window.history.back();
  }

  return (
    <React.Fragment>
      <header className="rep-nav">
        <div className="rep-nav-row">
          {show && (
            <span onClick={handleReturn} className="rep-nav-return">
              <i className="fa-solid fa-chevron-left"></i>
            </span>
          )}
          <span className="rep-nav-logo">
            <a href="" id="menu-logo-espace-client">
              <img src={logo} className="rep-logo-big" alt="logo" />
              <img src={logo} className="rep-logo-small" alt="logo" />
            </a>
          </span>
          <nav className="rep-nav-content">
            <ul id="menu">
              {routes
                .filter((p) => p.layout === "/admin" && p.isMenu)
                .map((p, i) => (
                  <li>
                    <Link
                      className={
                        isCurrent(`${p.layout}${p.path}`)
                          ? "rep-nav-active"
                          : ""
                      }
                      to={`${p.layout}${p.path}`}
                    >
                      {p.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>
          <div className="rep-nav-logout">
            <button
              type="button"
              id="menu-deconnecter"
              className="display_flex items-align-center no-wrap rep-nav-cta js-logout-btn"
              onClick={onLogout}
            >
              <span>Se déconnecter</span>
              <i className="fa-solid fa-right-from-bracket"></i>
            </button>
          </div>
        </div>
      </header>
      <div className="rep-main primary__content">
        <div
          className="dashboard primary__content"
          style={{ minHeight: "77vh" }}
        >
          {!isEmpty(getCurrent()) && (
            <h1 className="page-title hide">{getCurrent()}</h1>
          )}
          {children}
        </div>
      </div>
      <Footer />
      <nav className="rep-nav-mobile">
        <ul id="menu-mobile">
          {routes
            .filter((p) => p.layout === "/admin" && p.isMenu)
            .map((p, i) => (
              <li>
                <Link
                  className={
                    isCurrent(`${p.layout}${p.path}`) ? "rep-nav-active" : ""
                  }
                  to={`${p.layout}${p.path}`}
                >
                  <i className={p.icon ?? "fa-solid fa-square-user"}></i>
                </Link>
              </li>
            ))}
        </ul>
      </nav>
    </React.Fragment>
  );
};
