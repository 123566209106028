import { routeInterface } from "../routes";
import { LoginScreen } from "../screens/login";

export const PublicRoutes: routeInterface[] = [
  {
    component: LoginScreen,
    icon: null,
    isMenu: false,
    label: "Login",
    layout: "/home",
    name: "login",
    path: "/",
    iconTransition: null,
    subscribeable: false,
  },
];
