import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import facReducer from "./facReducer";
import deptReducer from "./deptReducer";
import studentReducer from "./studentReducer";
import promotionReducer from "./promotionReducer";
const store = configureStore({
  reducer: {
    user: userReducer,
    fac: facReducer,
    dept: deptReducer,
    student: studentReducer,
    promotion: promotionReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
