import { useFormik } from "formik";
import React from "react";
import { DillInput, DillSelect } from "../../components/input";
import { Button, Divider, Switch } from "antd";

export function CreateStudentScreen() {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      birthDate: "",
      gender: "f",
      promotion: "",
      department: "",
      faculty: "",
      option: "",
      matricule: "",
      phone: "",
      email: "",
      cardNumber: "",
      askCard: false,
    },
    onSubmit: onSave,
  });

  async function onSave() {}
  return (
    <React.Fragment>
      <main className="main">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-8 order-md-1 order-xs-2">
              <DillInput formik={formik} name="lastName" label="Nom *" />
              <DillInput formik={formik} name="firstName" label="Prénom *" />
              <DillInput
                formik={formik}
                name="birthDate"
                type="date"
                label="Date de naissance *"
              />
              <DillSelect
                formik={formik}
                label="Sexe"
                list={[
                  { title: "Femme", value: "f" },
                  { title: "Homme", value: "m" },
                ]}
                name="gender"
              />
              <DillInput formik={formik} name="matricule" label="Matricule" />
              <DillInput
                formik={formik}
                name="email"
                label="E-mail"
                type="email"
              />
              <DillInput
                formik={formik}
                name="phone"
                type="tel"
                label="Téléphone *"
              />
              <Divider />
              <DillSelect formik={formik} label="Faculté *" name="faculty" />
              <DillSelect
                formik={formik}
                label="Départment *"
                name="department"
              />
              <DillSelect formik={formik} label="Option *" name="option" />
              <DillSelect
                formik={formik}
                label="Promotion *"
                name="promotion"
              />
            </div>
            <div className="col-xs-12 col-md-4 order-xs-1 order-md-2">
              <div
                className="card"
                style={{
                  width: "100%",
                  height: 200,
                  background: "gold",
                  margin: "1rem 0",
                }}
              ></div>
              <Switch
                title="Demander une carte"
                checkedChildren="Demander une carte"
                checked={formik.values.askCard}
                onChange={(checked) =>
                  formik.setValues({ ...formik.values, askCard: checked })
                }
              />
              <Divider />
              <div className="row" style={{ alignItems: "center" }}>
                <div style={{ margin: "1.6rem auto" }}>
                  <Button type="primary">{`Enregistrer`}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
