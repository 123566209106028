import { Table } from "antd";
import React from "react";

export function StudentScreen() {
  return (
    <React.Fragment>
      <main className="main">
        <Table
          className="table"
          columns={[
            {
              title: "Nom",
            },
            {
              title: "Promotion",
            },
            {
              title: "Numéro de carte",
            },
            {
              title: "Etat de carte",
            },
          ]}
        />
      </main>
    </React.Fragment>
  );
}
