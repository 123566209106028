import { isEmpty } from "lodash";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { routeInterface, routes } from "../routes";
import { SplashScreen } from "./splash";
import { FallbackScreen } from "./fallback";
import { BlockScreen } from "./block";
import { NotFoundScreen } from "./404";
import { Navbar } from "../components/navbar";
import userHelper from "../utils/helpers/userHelper";
import { RootState } from "../redux/store";
import { ProSidebarProvider } from "react-pro-sidebar";
import { SidebarNav } from "../components/sidebar";
import { SiderAdmin } from "../components/siderAdmin";
import { setAppTitle } from "../utils/helpers/title";

export function AdminLayout() {
  const user = useSelector((state: RootState) => state.user.profil);
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState<routeInterface[]>(routes);
  const dispatch = useDispatch();
  const routerLocation = useLocation();
  const current = useLocation();

  function getCurrent() {
    const path = current.pathname;
    const item = routes.find((p) => `${p.layout}${p.path}` === path);
    if (!isEmpty(item)) {
      return item.title ?? item.label;
    } else {
      return "";
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setAppTitle(getCurrent());
  }, [routerLocation]);

  /*React.useEffect(() => {
    const { now, validatedAt } = subscription;
    const count = dateHelpers.getDifference(validatedAt, now, "days");
    const elements = routes.filter(
      (p) =>
        p.layout === "/admin" &&
        userHelper.isVisible(p.visibility, user, p.subscribeable, count >= 0)
    );
    setItems(elements);
  }, [user, subscription]);*/

  React.useEffect(() => {
    setItems(routes);
  }, []);

  React.useEffect(() => {
    checkUser();
  }, [user]);

  async function checkUser() {
    const userAdmin = await userHelper.getUserProfil();
    if (isEmpty(userAdmin)) {
      window.location.href = "/";
      /*return (
        <Navigate
          to="/"
          replace
          state={{ message: "Vous devez vous connecter avant tout" }}
        />
      );*/
    } else if (isEmpty(user)) {
      userHelper.setCurrentUser(userAdmin, dispatch);
    }
  }

  /*if (isEmpty(userAdmin)) {
    return (
      <Navigate
        to="/home/login"
        replace
        state={{ message: "Vous devez vous connecter avant tout" }}
      />
    );
  }*/
  async function init() {
    await userHelper.initData("", dispatch);
    /*if (isEmpty(user.uid)) {
      await userService
        .get(userAdmin.uid)
        .then((response) => {
          const data = response.data;
          userHelper.setData(data, dispatch);
        })
        .catch((reason) => {
          console.log(reason.response);
        });
    }*/
  }

  React.useEffect(() => {
    init();
    /*setAppTitle("Accueil");*/
  }, []);

  async function login() {
    /*const data = {
      username: user.code,
      password: user.code,
    };
    await userService
      .login(data)
      .then((response) => {
        const token = response.data.token;
        userHelper.setToken(token);
      })
      .catch((reason) => {
        console.warn(reason.response);
      });*/
  }

  /*React.useEffect(() => {
    //searchStudent();
    login();
  }, [user]);*/

  return (
    <React.Fragment>
      {/*<SplashScreen
        loading={loading || isEmpty(user.uid)}
        setLoading={setLoading}
      />*/}
      <ProSidebarProvider>
        <BlockScreen />
        <Navbar key="nav-admin">
          <SiderAdmin current="">
            <Suspense fallback={<FallbackScreen />}>
              <Routes>
                {items
                  .filter((p) => p.layout === "/admin")
                  .map((p, index) => {
                    return (
                      <Route
                        path={`${p.path}`}
                        element={<p.component />}
                        key={`${p.name}${index}`}
                      />
                    );
                  })}
                <Route
                  path={`*`}
                  element={<NotFoundScreen />}
                  key={`notfound`}
                />
              </Routes>
            </Suspense>
          </SiderAdmin>
        </Navbar>
      </ProSidebarProvider>
    </React.Fragment>
  );
}
