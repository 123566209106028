import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface facData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: facData = {
  current: {},
  list: [],
  all: [],
};

export const facSlice = createSlice({
  name: "facs",
  initialState,
  reducers: {
    setFaculty: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setFaculties: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllFaculties: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllFaculties, setFaculties, setFaculty } = facSlice.actions;
export default facSlice.reducer;
