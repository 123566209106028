import { Col, Divider, Image, Layout, Modal, Row } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "antd";
import logo from "../assets/images/min.png";
import { isEmpty, toNumber, upperFirst } from "lodash";
import { useDispatch } from "react-redux";
import { routes } from "../routes";
import userHelper from "../utils/helpers/userHelper";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";

function hasChildren(name?: string) {
  if (isEmpty(name)) {
    return false;
  }
  const list = routes.filter((p) => p.parent === name && p.isMenu);
  return list.length > 0;
}
interface mainMenuInterface {
  current?: string;
}
function MainMenus(props: mainMenuInterface) {
  const { current } = props;
  const [active, setActive] = React.useState("");
  const dispatch = useDispatch();

  const route = useLocation();

  function getIndex(path: string) {
    const index =
      `${path}` === route.pathname || `${path}/` === route.pathname ? 1 : -1;
    return index;
  }

  function logout() {
    userHelper.setLogout(dispatch);
    window.location.href = "/";
  }

  function handleLogout() {
    Modal.warning({
      centered: true,
      title: "Déconnexion",
      content: "Voulez-vous vous déconnecter?",
      okText: "Se déconnecter",
      cancelText: "Annuler",
      onCancel: () => window.history.back(),
      okCancel: true,
      onOk: logout,
    });
  }

  function isCurrentUrl(path: string) {
    if (path === current || path === current + "/") {
      return "yc_active";
    } else {
      return "";
    }
  }

  function handleToggleList(id: string) {
    if (active === id) {
      setActive("");
    } else {
      setActive(id);
    }
  }

  return (
    <React.Fragment>
      {routes
        .filter((p) => p.layout === "/admin" && p.isMenu)
        .map((p, index) => (
          <React.Fragment key={`route${index}`}>
            <>
              {isEmpty(p.parent) && !hasChildren(p.group) && (
                <MenuItem
                  component={<Link to={`${p.layout + p.path}`} />}
                  icon={
                    !isEmpty(p.icon) ? (
                      <i className={`${p.icon} yc_icon`}></i>
                    ) : undefined
                  }
                >
                  <span>{upperFirst(p.label)}</span>
                </MenuItem>
              )}
              {hasChildren(p.group) && (
                <SubMenu
                  label={p.label}
                  icon={
                    !isEmpty(p.icon) ? (
                      <i className={`${p.icon} yc_icon`}></i>
                    ) : undefined
                  }
                >
                  {routes
                    .filter((x) => x.parent === p.group && x.isMenu)
                    .map((x, indexLi) => (
                      <MenuItem
                        key={`li_${indexLi}`}
                        icon={
                          !isEmpty(x.icon) ? (
                            <i className={`${x.icon} yc_icon`}></i>
                          ) : undefined
                        }
                        component={
                          <Link
                            to={x.layout + x.path}
                            className={`${isCurrentUrl(x.layout + x.path)}`}
                          />
                        }
                      >
                        {upperFirst(x.label)}
                      </MenuItem>
                    ))}
                </SubMenu>
              )}
            </>
          </React.Fragment>
        ))}
      <Divider style={{ margin: "0.5rem 0" }} />
      <MenuItem
        onClick={handleLogout}
        icon={<i className="fa-solid fa-right-from-bracket yc_icon"></i>}
      >
        <span>{upperFirst("Se déconnecter")}</span>
      </MenuItem>
    </React.Fragment>
  );
}

const SiderAdmin: React.FC<{ current: string; children?: any }> = ({
  current,
  children,
}) => {
  const { collapseSidebar } = useProSidebar();
  const size = useWindowSize();

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      const width = size.width;
      if (toNumber(width) < 767) {
        collapseSidebar(true);
      } else {
        collapseSidebar(false);
      }
    });
  }, [size]);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Sidebar className="sidebar">
        <Menu>
          <MainMenus key="ds" current={current} />
        </Menu>
      </Sidebar>
      {children}
    </div>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export { SiderAdmin };
