import { Col, Layout, Row } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { routes } from "../routes";
//import userHelper from "../utils/helpers/userHelper";
import { RootState } from "../redux/store";
import userHelper from "../utils/helpers/userHelper";

export function HomeLayout() {
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user.profil);
  const dispatch = useDispatch();

  async function init() {
    const elem = await userHelper.getUserProfil();
    if (!isEmpty(elem)) {
      userHelper.setCurrentUser(elem, dispatch);
    }
  }

  React.useEffect(() => {
    init();
  }, [user]);

  /* if (userHelper.getData() != null) {
    window.location.href = "/admin";
  }
*/
  React.useEffect(() => {
    if (!isEmpty(location.state)) {
      const { message } = location.state;
      if (!isEmpty(message)) {
        //notification.error({message:"",description:message,duration:5,bottom:0})
      }
    }
  }, []);
  return (
    <React.Fragment>
      <Layout
        style={{
          height: "100vh",
          maxHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <Row gutter={16} style={{ width: "100%" }}>
          <Col className="arka-home-showing">
            <Layout className="arka-bg-home"></Layout>
          </Col>
          <Col className="arka-home-page">
            <RenderComponent />
          </Col>
        </Row>
      </Layout>
    </React.Fragment>
  );
}

function RenderComponent() {
  return (
    <React.Fragment>
      <Routes>
        {routes
          .filter((p) => p.layout === "/home")
          .map((p, index) => {
            return (
              <Route
                path={`${p.path}`}
                element={<p.component />}
                key={`${p.name}${index}`}
              />
            );
          })}
      </Routes>
    </React.Fragment>
  );
}
