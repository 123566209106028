import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface deptData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: deptData = {
  current: {},
  list: [],
  all: [],
};

export const deptSlice = createSlice({
  name: "dept",
  initialState,
  reducers: {
    setDepartment: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setDepartments: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllDepartments: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllDepartments, setDepartment, setDepartments } =
  deptSlice.actions;
export default deptSlice.reducer;
