import React from "react";
import logo from "../assets/images/logo.png";

export const Footer = () => {
  return (
    <footer className="wrapper__footer">
      <img src={logo} alt="logo" />
    </footer>
  );
};
